import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// core components
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardTitle,
	Container,
	Row,
	Col,
} from 'reactstrap';

// core components

function SectionPricing(rest) {
	const dispatch = useDispatch();
	useEffect(() => {
		try {
			dispatch(rest.removeError());
			dispatch(rest.removeSuccess());
		} catch (err) {
			console.log(err);
		}
	}, []);
	return (
		<>
			<Navbar />
			<div id='pricing'>
				<div
					className='pricing-3 section-image'
					style={{
						backgroundImage: 'url(' + require('../Images/3.jpg') + ')',
					}}>
					<Container>
						<Row>
							<Col className='ml-auto mr-auto text-center' md='6'>
								<h2 className='title'>
									<strong>Build Your Online Store For Free</strong>
								</h2>
								<h5
									className='description'
									style={{ textShadow: '0px 0px 1px #fff' }}>
									<strong>
										Begin with our free package and upgrade whenever you&apos;re
										ready to market your business locally. Enjoy the flexibility
										to grow your online presence at your own pace!
									</strong>
								</h5>
							</Col>
						</Row>
						<br />
						<br />
						<br />
						<Row>
							<Col md='4'>
								<Card
									className='card-pricing'
									data-background='image'
									style={{ background: '#577e8f' }}>
									<CardBody>
										<h6 className='card-category'>Free</h6>
										<CardTitle tag='h1'>
											<small>$</small>0 <small>/mo</small>
										</CardTitle>
										<ul>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												Online Store
											</li>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												Unlimited products
											</li>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												Unlimited Blog Posts
											</li>
											<li>
												<b>
													<i className='bi bi-x-lg' />
												</b>{' '}
												Team Members Profile
											</li>
											<li>
												<b>
													<i className='bi bi-x-lg' />
												</b>{' '}
												Coupons
											</li>
											<li>
												<b>
													<i className='bi bi-x-lg' />
												</b>{' '}
												Sales channels
											</li>
											<li>
												<b>
													<i className='bi bi-x-lg' />
												</b>{' '}
												Local Marketing
											</li>
											<li>
												<b>
													<i className='bi bi-x-lg' />
												</b>{' '}
												24/7 support
											</li>
											<li>
												<b>
													<i className='bi bi-x-lg' />
												</b>{' '}
												Sell Online
											</li>
										</ul>
										<Button
											className='btn-round'
											color='success'
											href='/signup'>
											Get Started
										</Button>
									</CardBody>
								</Card>
							</Col>
							<Col md='4'>
								<Card
									className='card-pricing'
									data-background='image'
									style={{
										backgroundColor: '#096187',
									}}>
									<CardBody>
										<h6 className='card-category'>Premium</h6>
										<CardTitle tag='h1'>
											<small>$</small>
											300 <small>/mo</small>
										</CardTitle>
										<ul>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												Online Store
											</li>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												Unlimited products
											</li>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												Unlimited Blog Posts
											</li>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												Team Members Profile
											</li>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												Coupons
											</li>
											<li>
												<b>
													<i className='bi bi-x-lg' />
												</b>{' '}
												Sales channels
											</li>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												Local Marketing in 2 Buildings
											</li>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												24/7 support
											</li>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												Sell Online
											</li>
										</ul>
										<Button
											className='btn-round'
											color='success'
											href='#pablo'
											disabled
											onClick={(e) => e.preventDefault()}>
											Get Started
										</Button>
									</CardBody>
								</Card>
							</Col>
							<Col md='4'>
								<Card
									className='card-pricing'
									data-background='image'
									style={{ backgroundColor: '#1495cc' }}>
									<CardBody>
										<h6 className='card-category'>Platinum</h6>
										<CardTitle tag='h1'>
											<small>$</small>
											999 <small>/mo</small>
										</CardTitle>
										<ul>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												Online Store
											</li>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												Unlimited products
											</li>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												Unlimited Blog Posts
											</li>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												Team Member Profile
											</li>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												Coupons
											</li>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												Sales channels
											</li>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												Local Marketing in 10 Buildings
											</li>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												24/7 support
											</li>
											<li>
												<b>
													<i className='bi bi-check2-circle' />
												</b>{' '}
												Sell Online
											</li>
										</ul>
										<Button
											className='btn-round'
											color='danger'
											href='#'
											disabled
											onClick={(e) => e.preventDefault()}>
											Get Started
										</Button>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
				<Footer />
			</div>
		</>
	);
}

export default SectionPricing;
